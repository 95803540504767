import React from 'react';
import styled from '@emotion/styled';
import type { WCmsFaqPageView, WPublicThemeV2View } from '@zola/svc-web-api-ts-client';

import TitleImgDesc from '~/components/publicWebsiteV2/common/TitleImgDesc';
import { PageAnimationWrapper } from '~/components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import getNonHomeCmsHeroImg from '~/components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

import {
  HorizontalColumn,
  Divider,
} from '~/components/publicWebsiteV2/util/pageCustomizationOrientation.styles';
import { FoilAnimationWrapper } from '~/components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';
import { FoilAnimationProvider } from '~/components/publicWebsiteV2/common/FoilAnimation/FoilAnimationContext/FoilAnimationProvider';
import { useAnimationContext } from '~/components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import { FaqContainer, Faq, Question } from './Faqs.styles';

type FaqsProps = {
  pageData?: WCmsFaqPageView;
};
/**
 * Used for single and multi page sections
 */
const Faqs = ({ pageData }: FaqsProps): JSX.Element => {
  const { title, description, faqs, header_image_url, images } = pageData || {};
  const {
    state: {
      components: {
        styleCmsEntityHeaderFont,
        styleCmsEntityBodyFont,
        cmsEntityComponentBodyFontValues,
      },
      wedding,
    },
  } = useWebsiteThemeContext();
  const { isMobileDevice } = useAnimationContext();
  const isSinglePageLayout =
    wedding?.public_theme_v2?.layout_type ===
    (('SINGLE_PAGE' as unknown) as WPublicThemeV2View.LayoutTypeEnum);
  const animationIndexModifier = isSinglePageLayout || isMobileDevice ? 1 : 0;

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);

  const orientation =
    wedding?.public_theme_v2?.components?.CMS_FAQ?.option_type_values?.ORIENTATION?.value ||
    'HORIZONTAL';

  const P = styled.p`
    white-space: pre-wrap;
  `;
  const StyledP = styleCmsEntityBodyFont(P);

  const EmptyStateMessage = styled(StyledP)`
    text-align: center;
  `;

  const showEmptyStateMessage = faqs?.length === 0 && !description;

  const StyledQuestion = styleCmsEntityHeaderFont(Question);

  return (
    <>
      <TitleImgDesc
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
        description={description}
      />
      {faqs && faqs.length > 0 && (
        <FaqContainer>
          {faqs.map((faq, i) => {
            const direction = i % 2 ? 'left' : 'right';
            return (
              <PageAnimationWrapper
                index={i + animationIndexModifier}
                key={faq.id}
                animationProps={{
                  direction: { wipeDirection: direction, panDirection: direction },
                }}
              >
                <FoilAnimationProvider>
                  <Faq orientation={orientation}>
                    <HorizontalColumn orientation={orientation}>
                      <h5>Question</h5>
                      <StyledQuestion>
                        <FoilAnimationWrapper text={faq.question} />
                      </StyledQuestion>
                    </HorizontalColumn>
                    {orientation === 'HORIZONTAL' && (
                      <Divider
                        style={{ backgroundColor: `#${cmsEntityComponentBodyFontValues.color}` }}
                      />
                    )}
                    <HorizontalColumn orientation={orientation}>
                      <h5>Answer</h5>
                      <StyledP>{faq.answer}</StyledP>
                    </HorizontalColumn>
                  </Faq>
                </FoilAnimationProvider>
              </PageAnimationWrapper>
            );
          })}
        </FaqContainer>
      )}
      {showEmptyStateMessage && (
        <PageAnimationWrapper>
          <EmptyStateMessage>Hard at work on these... Talk soon.</EmptyStateMessage>
        </PageAnimationWrapper>
      )}
    </>
  );
};

export default Faqs;
